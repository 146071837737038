<template>
	<Modal width="500px">
		<template #modal-title>
			{{ isCancel ? 'Cancel' : isArchive ? 'Archive' : 'Delete' }} {{ entityName }}
		</template>
		<template	#modal-content>
			<span v-if="isCancel">
				Are you sure you wish to cancel this {{ entityType }}?
			</span>
			<span v-else>
				Are you sure you wish to {{ !canBeRecovered ? 'delete' : 'archive' }} this {{ entityType }}? <br>
				{{ !canBeRecovered ? 'You will not be able to recover it if you confirm its deletion.' : '' }}
			</span>
			<slot name="additional-content" />
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
			<DeleteButton @click="$emit('delete')">
				{{ isCancel ? 'Confirm' : isArchive ? 'Archive' : 'Delete' }}
			</DeleteButton>
		</template>
	</Modal>
</template>

<script>
import Modal from './modal.vue'
import SecondaryActionButton from './secondary-action-button.vue'
import DeleteButton from './delete-button.vue'
export default {
	components: { DeleteButton, SecondaryActionButton, Modal },
	props: {
		entityName: String,
		entityType: String,
		canBeRecovered: Boolean,
		isArchive: Boolean,
		isCancel: Boolean
	}
}
</script>
