<template>
	<div
		class="d-flex flex-row cassie-horizontal-md"
	>
		<SecondaryActionButton
			:input-value="selectShowFilters"
			@click="changeShowFilters(!selectShowFilters)"
		>
			<v-icon>
				mdi-filter
			</v-icon>
			Filter
		</SecondaryActionButton>
		<div
			v-if="selectShowFilters"
			class="d-flex flex-row flex-wrap cassie-horizontal-sm mb-n2 cassie-100-w"
		>
			<slot name="before-filters" />
			<TextField
				v-if="!hideSearchQueryFilter"
				:value="searchQuery"
				:label="searchQueryLabel"
				append-icon="mdi-magnify"
				class="mb-2"
				@input="emitSearchQuery"
			/>
			<Dropdown
				v-if="!hideBrandFilter && clientMultiBranded"
				:value="selectedBrandId"
				:label="`${adminPortalBrandFieldLabel}`"
				:items="[{ value: null, text: 'View All' }, ...brandOptions]"
				custom-sort
				class="mb-2"
				@input="emitSelectedBrandId"
			/>
			<slot name="after-filters" />
			<template v-if="showMetaDataFilters">
				<Dropdown
					:value="selectedMetaDataKeyId"
					label="Metadata Key"
					class="mb-2"
					:items="metaDataKeyItems"
					custom-sort
					@input="emitSelectedMetaDataKeyId"
				/>
				<TextField
					:value="metaDataSearchQuery"
					append-icon="mdi-magnify"
					label="Metadata Value"
					class="mb-2"
					@input="emitMetaDataSearchQuery"
				/>
			</template>
			<SecondaryActionButton
				v-if="showClearFilters"
				@click="clearFilters()"
			>
				Clear Filters
			</SecondaryActionButton>
		</div>

		<slot
			v-if="$slots['tooltip']"
			name="tooltip"
		/>
		<v-spacer />
		<slot
			v-if="$slots['action']"
			name="action"
		/>
	</div>
</template>

<script>
import { brandOptions, clientMultiBranded } from '../state/brands.js'
import { adminPortalBrandFieldLabel, waitForLoad } from '../state/config-keys.js'
import SecondaryActionButton from './secondary-action-button.vue'
import TextField from './text-field.vue'
import Dropdown from './dropdown.vue'
import { getExternalMetaData } from '../utils/api/external-meta-data.js'
import { selectShowFilters, changeShowFilters } from '../state/filters.js'

export default {
	components: { Dropdown, TextField, SecondaryActionButton },
	props: {
		searchQuery: String,
		searchQueryLabel: {
			type: String,
			default: 'Search'
		},
		selectedBrandId: Number,
		hideBrandFilter: Boolean,
		hideSearchQueryFilter: Boolean,
		showClearFilters: {
			type: Boolean,
			default: true
		},
		showMetaDataFilters: Boolean,
		selectedMetaDataKeyId: Number,
		metaDataSearchQuery: String,
		openByDefault: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			brandOptions,
			waitForLoad,
			adminPortalBrandFieldLabel,
			clientMultiBranded,
			selectShowFilters,
			changeShowFilters
		}
	},
	data () {
		return {
			metaDataKeys: []
		}
	},
	computed: {
		metaDataKeyItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				...this.metaDataKeys.map(metaDataKey => ({
					value: metaDataKey.id,
					text: metaDataKey.name
				})).sort((a, b) => a.text.localeCompare(b.text))
			]
		}
	},
	async created () {
		await waitForLoad()
		const { externalMetaData } = await getExternalMetaData()
		this.metaDataKeys = externalMetaData
		if (this.openByDefault) {
			this.changeShowFilters(true)
		}
	},
	methods: {
		emitSearchQuery (value) {
			this.$emit('update:searchQuery', value)
			this.$emit('persistSearchQuery', value)
		},
		emitSelectedBrandId (value) {
			this.$emit('update:selectedBrandId', value)
			this.$emit('persistSelectedBrand', value)
		},
		emitMetaDataSearchQuery (value) {
			this.$emit('update:metaDataSearchQuery', value)
			this.$emit('persistMetaDataSearchQuery', value)
		},
		emitSelectedMetaDataKeyId (value) {
			this.$emit('update:selectedMetaDataKeyId', value)
			this.$emit('persistSelectedMetaDataKeyId', value)
		},
		clearFilters () {
			this.emitSearchQuery('')
			this.emitSelectedBrandId(null)
			this.emitMetaDataSearchQuery('')
			this.emitSelectedMetaDataKeyId(null)
			this.$emit('clearFilters')
		}
	}
}
</script>
