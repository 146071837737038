import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../../utils/date-formatting.js'

export const getProgressiveProfiles = () => {
	return configApi.get(`/api/ProgressiveProfiling`).then(({ data }) => ({
		profiles: data.profiles.map(progressiveProfile => ({
			...progressiveProfile,
			colourSchemeJson: JSON.parse(progressiveProfile.colourSchemeJson),
			createdDate: format(new Date(progressiveProfile.createdDate), DATE_FORMAT)
		})),
		lookupData: data.lookupData
	}))
}

export const postProgressiveProfile = profile => configApi.post('/api/ProgressiveProfiling', profile)

export const putProgressiveProfile = profile => configApi.put('/api/ProgressiveProfiling', profile)

export const deleteProgressiveProfile = profileId => configApi.delete(`/api/ProgressiveProfiling?id=${profileId}`)

export const getProgressiveProfilingEmbedCode = (profileId, dynamic, accessKey) => {
	let embedCodeEndpoint = `/api/ProgressiveProfiling/EmbedCode?ProgressiveProfileId=${profileId}&AccessKey=${accessKey}`
	if (dynamic) embedCodeEndpoint += `&Dynamic=${dynamic}`
	return configApi.get(embedCodeEndpoint)
}
