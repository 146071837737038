<template>
	<ViewLayout>
		<template #header-title>
			Progressive Profiling
		</template>
		<template #header-caption>
			Add, edit and manage progressive profiling criteria
		</template>
		<template #content>
			<FilterBar
				:search-query.sync="progressiveProfilingSearchQuery"
				:selected-brand-id.sync="selectedAdminPortalBrandId"
				search-query-label="Profile Name"
				@persistSearchQuery="changeProgressiveProfilingSearchQuery"
				@persistSelectedBrandId="changeAdminPortalBrandFilter"
			>
				<template #action>
					<PrimaryActionButton
						v-if="userCanCreateAndUpdate"
						@click="createOnClick"
					>
						<v-icon left>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					Progressive Profiling
					<v-tooltip
						slot="append"
						right
					>
						<template #activator="{ on }">
							<v-icon
								light
								class="ml-2"
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<span>
							This module allows the configuration of a chain of widgets to obtain first party data on your data subjects.
						</span>
					</v-tooltip>
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="filteredProgressiveProfiles"
						sort-by="id"
						sort-desc
						@click:row="editOnClick"
					>
						<template #item.action="{ item }">
							<IconButton
								v-if="(item.brandId !== 0 || (item.brandId === 0 && userHasUniversalBrand)) && userCanCreateAndUpdate"
								tooltip-text="Edit Progressive Profile"
								@click="progressiveProfileToEdit = item"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-else
								tooltip-text="View Progressive Profile"
								@click="progressiveProfileToEdit = item"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="(item.brandId !== 0 || (item.brandId === 0 && userHasUniversalBrand)) && userCanDelete"
								tooltip-text="Delete Progressive Profile"
								@click.stop.prevent="progressiveProfileToRemove = item"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
					<ConfirmDeleteModal
						v-if="progressiveProfileToRemove"
						:entity-name="progressiveProfileToRemove.profileName"
						entity-type="Progressive Profile"
						@close="progressiveProfileToRemove = null"
						@delete="deleteProgressiveProfile"
					/>
				</template>
			</SectionCard>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { MANAGE_PROGRESSIVE_PROFILE } from '../../../../router/route-names.js'
import { getProgressiveProfiles, deleteProgressiveProfile } from '../../../../../../shared/utils/api/progressive-profiling.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import { changeProgressiveProfilingSearchQuery, progressiveProfilingSearchQuery } from '../../../../../../shared/state/progressive-profiling.js'
import { CAN_CREATE_UPDATE_PROGRESSIVE_PROFILING, CAN_DELETE_PROGRESSIVE_PROFILING } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		PrimaryActionButton,
		FilterBar,
		DataTable,
		ConfirmDeleteModal,
		SectionCard,
		ViewLayout,
		IconButton
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeProgressiveProfilingSearchQuery,
			progressiveProfilingSearchQuery
		}
	},
	data () {
		return {
			progressiveProfileToEdit: null,
			selectedBrandId: null,
			progressiveProfileToRemove: null,
			progressiveProfiles: [],
			tableHeaders: [
				{
					value: 'id',
					text: 'ID'
				},
				{
					value: 'brandName',
					text: 'Brand'
				},
				{
					value: 'profileName',
					text: 'Profile Name'
				},
				{
					value: 'createdDate',
					text: 'Created Date'
				},
				{
					value: 'action',
					text: 'Action',
					sortable: false,
					width: '10%'
				}
			],
			showAddModal: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand', 'userBrands']),
		...mapGetters('lookupData', ['progressiveProfileLookupData']),
		filteredProgressiveProfiles () {
			return this.progressiveProfiles.filter(({ profileName, brandId }) => {
				let check = true
				if (progressiveProfilingSearchQuery.value) check = profileName.toLowerCase().includes(progressiveProfilingSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			})
		},
		userCanCreateAndUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_PROGRESSIVE_PROFILING)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_PROGRESSIVE_PROFILING)
		}
	},
	created () {
		this.loadProgressiveProfiling()
	},
	methods: {
		...mapActions('lookupData', ['storeProgressiveProfilingLookupData']),
		createOnClick () {
			this.$router.push({
				name: MANAGE_PROGRESSIVE_PROFILE,
				params: {
					userCanCreateAndUpdate: this.userCanCreateAndUpdate
				}
			})
		},
		editOnClick (progressiveProfileToEdit) {
			this.$router.push({
				name: MANAGE_PROGRESSIVE_PROFILE,
				params: {
					progressiveProfileToEdit: progressiveProfileToEdit,
					userCanCreateAndUpdate: this.userCanCreateAndUpdate && this.userHasUniversalBrand || this.userHasBrand(progressiveProfileToEdit.brandId)
				}
			})
		},
		async deleteProgressiveProfile () {
			await deleteProgressiveProfile(this.progressiveProfileToRemove.id)
			showSnackbar('Progressive Profile deleted successfully.')
			this.progressiveProfileToRemove = null
			this.loadProgressiveProfiling()
		},
		async loadProgressiveProfiling () {
			const { profiles, lookupData } = await getProgressiveProfiles()
			if (!this.progressiveProfileLookupData) {
				this.storeProgressiveProfilingLookupData(lookupData)
			}
			this.progressiveProfiles = profiles
		},
		userHasBrand (brandId) {
			return this.userBrands.includes(brandId)
		}
	}
}
</script>
